
.img {
    width: 100%;
}

.card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(350px, auto);
    background: var(--darkGrey);
    color: rgba(255,255,255,.5);
    overflow: hidden;
    margin-bottom: 2rem;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.featured-card {
    display: grid;
    margin-top: 4rem;
    grid-template-columns: 6fr 3fr;
    grid-auto-rows: minmax(350px, auto);
    background: var(--mainGrey);
    color: #7c7c7d;
    overflow: hidden;
    margin-bottom: 2rem;
    border-radius: 5px;
    /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
    /* transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
}

.card h3 {
    color: var(--mainWhite);
    margin-bottom: 1rem;
}

.featured-card h3 {
    color: var(--darkGrey);
    margin-bottom: 1rem;
}


.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
}

.featured-card img {
    width: 100%;
    height: auto;
    object-fit: cover;
    background-repeat: no-repeat;
}

.card:nth-child(even) img {
    order: 2;
}

.card div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.featured-card div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card:nth-child(even) > div {
    text-align: right;
    padding: 2rem 0px 2rem 2rem;
}


.card:nth-child(odd) > div {
    text-align: left;
    padding: 2rem 2rem 2rem 0px;
}

.featured-card > div {
    text-align: left;
    padding: 2rem 2rem 2rem 0px;
}

.card:nth-child(odd) > div .border-box {
    padding-left: 2rem;
    border-left: .5rem solid var(--primaryColor);
}

.featured-card > div .border-box {
    padding-left: 2rem;
    border-left: .5rem solid var(--primaryColor);
}

.card:nth-child(even) > div .border-box {
    padding-right: 2rem;
    border-right: .5rem solid var(--primaryColor);
}

.card:nth-child(odd) > div .text-box {
    align-items: flex-start;
}

.featured-card > div .text-box {
    align-items: flex-start;
}

.card:nth-child(even) > div .text-box {
    align-items: flex-end;
}

.border-box {
    padding: 0px;

}

.text-box {
    padding: 0px;
}

.text-box .btn {
    width: 130px;
    height: 37px;
    font-size: 9px;
    padding: 0px;
}

.text-box .btn-primary {
    margin-right: 1rem;
}

hr {
    border-style: none;
}

.card hr {
    width: 130px;
    margin: .5rem 0px 0px 0px;
    border-bottom: .25rem solid var(--primaryColor);
}

.featured-card hr {
    width: 130px;
    margin: .5rem 0px 0px 0px;
    border-bottom: .25rem solid var(--primaryColor);
}

.card:nth-child(even) hr {
    margin-right: 0px;
    margin-left: auto;
}

#projects {
    margin-bottom: 5rem;
}

@media (max-width: 992px) {

    .featured-card{
        display: block;
    }

    .featured-card > div .border-box {
        border-left: none;
    }

    .featured-card > div .text-box {
        align-items: center;
    }

    .featured-card > div {
        text-align: center;
        padding: 2rem 2rem 2rem 0px;
    }

    .featured-card hr {
        display: none;
    }

    .card {
        display: block;
    }

    .card div {
        text-align: center;
    }
    
    .card:nth-child(n) > div .text-box {
        align-items: center;
    }

    .card:nth-child(n) > div .border-box {
        border-right: none;
        border-left: none;
    }

    .card hr {
        display: none;
    }
  }

@media (min-width: 992px) and (max-width: 1200px) {
    .featured-card {
        grid-template-columns: 5fr 3fr;
    }

    .featured-card > div {
        height: 299px;
    }
}