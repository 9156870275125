@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primaryColor: #388f40;
  --primaryColorLight: #3fa049;
  --primaryColorDark: #307c37;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #f8f9fa;
  --lightDarkGrey: #cecece;
  --darkGrey: #272727;
  --mainTransition: all 0.0s linear;
  --mainSpacing: .0625em;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

html {
  overflow-x: hidden;
}

body {
  /* padding-top: 62px; */
  color: var(--mainBlack);
  background: var(--mainGrey);
  font-family: 'Open Sans';
  line-height: 1.4;
  overflow-x: hidden;
}
h1 {
  font-weight: 700;
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
  font-weight: 400;
}
h4 {
  font-size: .8rem;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.btn {
  display: inline-block;
  font-weight: 600;
  -webkit-box-shadow: 0 .1875rem .1875rem 0 rgba(0,0,0,.1)!important;
  box-shadow: 0 .1875rem .1875rem 0 rgba(0,0,0,.1)!important;
  padding: 1.25rem 2rem;
  font-family: 'Open Sans';
  font-size: 80%;
  text-transform: uppercase;
  letter-spacing: .15rem;
  border: 0;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  text-decoration: none;
}

.btn:hover {
  cursor: pointer;
}

.btn-primary {
  /* display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing); */
  color: var(--mainWhite);
  background: var(--primaryColor);
  /* padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500; */
}
.btn-primary:hover {
  background-color: var(--primaryColorLight);
}

.btn-secondary {
  background-color: var(--mainGrey);
}

.btn-secondary:hover {
  background-color: var(--lightDarkGrey);
}

.btn:focus {
  outline: none;
}

#big-btn {
  font-weight: 400;
}

/* start container */
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
      width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}
/* end container */