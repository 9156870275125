.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0.75rem 2rem;
  background: transparent;
  z-index: 1;
}
.nav-header {
  display: flex;
  justify-content: space-between;
}
.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  font-size: 1.5rem;
  color: var(--mainWhite);
}
.nav-links {
  height: 0;
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
  /* background-color: var(--mainWhite); */
} 
.nav-links a {
  font-family: 'Open Sans';
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  color: rgba(255,255,255,.8);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
  color: var(--mainWhite, 30%);
}
.show-nav {
  height: auto;
}
.logo-div {
  /* width: 250px; */
  height: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-text {
  font-family: 'Open Sans';
  color: rgba(255,255,255,.8);
  text-decoration: none;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: var(--mainSpacing);
}


.navbar.nav-scrolled {
  background-color: var(--mainGrey);
}

.logo-text.nav-scrolled {
  color: var(--darkGrey);
}

.nav-icon.nav-scrolled {
  color: var(--darkGrey);
}

.nav-links.nav-scrolled a {
  color: var(--darkGrey);
  background-color: var(--mainGrey);
}

.nav-links.nav-scrolled a:hover {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 4rem;
    background-color: transparent;
  }
  .nav-links a {
    margin: 0 1rem;
    padding: 0.5rem 0;
  }
}