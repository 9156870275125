@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primaryColor: #388f40;
  --primaryColorLight: #3fa049;
  --primaryColorDark: #307c37;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #f8f9fa;
  --lightDarkGrey: #cecece;
  --darkGrey: #272727;
  --mainTransition: all 0.0s linear;
  --mainSpacing: .0625em;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

html {
  overflow-x: hidden;
}

body {
  /* padding-top: 62px; */
  color: #222;
  color: var(--mainBlack);
  background: #f8f9fa;
  background: var(--mainGrey);
  font-family: 'Open Sans';
  line-height: 1.4;
  overflow-x: hidden;
}
h1 {
  font-weight: 700;
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
  font-weight: 400;
}
h4 {
  font-size: .8rem;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.btn {
  display: inline-block;
  font-weight: 600;
  box-shadow: 0 .1875rem .1875rem 0 rgba(0,0,0,.1)!important;
  padding: 1.25rem 2rem;
  font-family: 'Open Sans';
  font-size: 80%;
  text-transform: uppercase;
  letter-spacing: .15rem;
  border: 0;
  line-height: 1.5;
  border-radius: .25rem;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  text-decoration: none;
}

.btn:hover {
  cursor: pointer;
}

.btn-primary {
  /* display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing); */
  color: #fff;
  color: var(--mainWhite);
  background: #388f40;
  background: var(--primaryColor);
  /* padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500; */
}
.btn-primary:hover {
  background-color: #3fa049;
  background-color: var(--primaryColorLight);
}

.btn-secondary {
  background-color: #f8f9fa;
  background-color: var(--mainGrey);
}

.btn-secondary:hover {
  background-color: #cecece;
  background-color: var(--lightDarkGrey);
}

.btn:focus {
  outline: none;
}

#big-btn {
  font-weight: 400;
}

/* start container */
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
      width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}
/* end container */
.defaultHero {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 35rem;
  padding: 15rem 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(22, 22, 22, 0.3)), color-stop(60%, rgba(22, 22, 22, 0.7)), to(#161616)), url(/static/media/officeOne.22680603.jpg);
  /* background: linear-gradient(to bottom, rgba(22, 22, 22, 0.3) 0%, rgba(22, 22, 22, 0.7) 75%, #161616 100%), url("../../images/bg-masthead.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

.banner {
display: inline-block;
background: transparent;
color: var(--mainWhite);
padding: 2rem 1rem;
text-align: center;
text-transform: capitalize;
letter-spacing: var(--mainSpacing);
border-radius: 3px;
}
.banner h1 {
    font-family: 'Nunito';
    font-size: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: 0.8rem;
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.2));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: 400;
    margin-bottom: 0px;
}
.banner h2 {
    max-width: 20rem;
    font-size: 1rem;
    margin-bottom: 1.5rem !important;
}
.banner p {
font-size: 1.2rem;
margin-bottom: 2rem;
}

.banner-subtitle{
    color: rgba(255,255,255,.8);
    margin: .5rem auto 1.5rem auto;
}

.banner .social {
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
}

.icon-button {
    text-align: center;
    height: 3rem;
    width: 3rem;
    background: rgba(255,255,255,.1);
    border-radius: 100%;
    line-height: 3rem;
    color: rgba(255,255,255,.5);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px .5rem;
    font-size: 20px;
}

.icon-button:hover {
  color: rgba(255, 255, 255, 0.8);
}

@media screen and (min-width: 576px) {
.banner {
    padding: 2rem 3rem;
}
.banner h1 {
    font-size: 4rem;
    line-height: 4rem;
}
}
@media screen and (min-width: 992px) {
.banner {
    padding: 2rem 6rem;
}
.banner h1 {
    font-size: 6.5rem;
    line-height: 6.5rem;
    letter-spacing: 0.8rem;
}
.banner h2 {
    max-width: 30rem;
    font-size: 1.25rem;
}
}
.about-section {
    padding-top: 8rem;
    padding-bottom: 8rem;
    background: #272727;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-gradient(linear, left bottom, left top, from(#333333), color-stop(#272727), to(#161616));
    background: linear-gradient(to top, #333333, #272727, #161616); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.about-section h1 {
    color: var(--mainWhite);
    font-size: 1.9rem;
    letter-spacing: var(--mainSpacing);
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.about-section p {
    color: rgba(255,255,255,.5);
    /* width: 50vw; */
    font-size: 1rem;
    letter-spacing: var(--mainSpacing);
    line-height: 1.5;
    font-weight: 500;
}

.about-section p > a {
    color: var(--primaryColor);
    font-size: 1rem;
    letter-spacing: var(--mainSpacing);
    line-height: 1.5;
    font-weight: 500;
    text-decoration: none;

}
.about-section p > a:hover {
    color: var(--primaryColorLight);
    
}

@media (min-width: 992px) {
    .about-section p {
        max-width: 66.666667%;
        margin-left: auto;
        margin-right: auto;
    }
}

.img {
    width: 100%;
}

.card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(350px, auto);
    background: var(--darkGrey);
    color: rgba(255,255,255,.5);
    overflow: hidden;
    margin-bottom: 2rem;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    -webkit-transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.featured-card {
    display: grid;
    margin-top: 4rem;
    grid-template-columns: 6fr 3fr;
    grid-auto-rows: minmax(350px, auto);
    background: var(--mainGrey);
    color: #7c7c7d;
    overflow: hidden;
    margin-bottom: 2rem;
    border-radius: 5px;
    /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
    /* transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
}

.card h3 {
    color: var(--mainWhite);
    margin-bottom: 1rem;
}

.featured-card h3 {
    color: var(--darkGrey);
    margin-bottom: 1rem;
}


.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
}

.featured-card img {
    width: 100%;
    height: auto;
    object-fit: cover;
    background-repeat: no-repeat;
}

.card:nth-child(even) img {
    order: 2;
}

.card div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.featured-card div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card:nth-child(even) > div {
    text-align: right;
    padding: 2rem 0px 2rem 2rem;
}


.card:nth-child(odd) > div {
    text-align: left;
    padding: 2rem 2rem 2rem 0px;
}

.featured-card > div {
    text-align: left;
    padding: 2rem 2rem 2rem 0px;
}

.card:nth-child(odd) > div .border-box {
    padding-left: 2rem;
    border-left: .5rem solid var(--primaryColor);
}

.featured-card > div .border-box {
    padding-left: 2rem;
    border-left: .5rem solid var(--primaryColor);
}

.card:nth-child(even) > div .border-box {
    padding-right: 2rem;
    border-right: .5rem solid var(--primaryColor);
}

.card:nth-child(odd) > div .text-box {
    align-items: flex-start;
}

.featured-card > div .text-box {
    align-items: flex-start;
}

.card:nth-child(even) > div .text-box {
    align-items: flex-end;
}

.border-box {
    padding: 0px;

}

.text-box {
    padding: 0px;
}

.text-box .btn {
    width: 130px;
    height: 37px;
    font-size: 9px;
    padding: 0px;
}

.text-box .btn-primary {
    margin-right: 1rem;
}

hr {
    border-style: none;
}

.card hr {
    width: 130px;
    margin: .5rem 0px 0px 0px;
    border-bottom: .25rem solid var(--primaryColor);
}

.featured-card hr {
    width: 130px;
    margin: .5rem 0px 0px 0px;
    border-bottom: .25rem solid var(--primaryColor);
}

.card:nth-child(even) hr {
    margin-right: 0px;
    margin-left: auto;
}

#projects {
    margin-bottom: 5rem;
}

@media (max-width: 992px) {

    .featured-card{
        display: block;
    }

    .featured-card > div .border-box {
        border-left: none;
    }

    .featured-card > div .text-box {
        align-items: center;
    }

    .featured-card > div {
        text-align: center;
        padding: 2rem 2rem 2rem 0px;
    }

    .featured-card hr {
        display: none;
    }

    .card {
        display: block;
    }

    .card div {
        text-align: center;
    }
    
    .card:nth-child(n) > div .text-box {
        align-items: center;
    }

    .card:nth-child(n) > div .border-box {
        border-right: none;
        border-left: none;
    }

    .card hr {
        display: none;
    }
  }

@media (min-width: 992px) and (max-width: 1200px) {
    .featured-card {
        grid-template-columns: 5fr 3fr;
    }

    .featured-card > div {
        height: 299px;
    }
}
.contact-section {
    background-color: var(--darkGrey);
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social {
    margin-top: 5rem;
    display: flex;
    align-items: center;
}

.contact-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2rem;
}

.contact-card {
    background-color: var(--mainGrey);
    height: 205px;
    width: 100%;
    border-radius: 5px;
    padding: 24px 0px 24px 0px;
    border-bottom: .25rem solid var(--primaryColor);
}

.contact-card-body {
    flex-direction: column;
    padding: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}


.contact-card-body h4 {
    letter-spacing: .15rem;
    margin: 0px;
}

.contact-icon {
    font-size: 1.15rem;
    color: var(--primaryColor);
    margin-bottom: .5rem;
}

.contact-card-body > .card-line {
    width: 48px;
    height: 4px;
    margin: 24px auto 24px auto;
    border-radius: 0px;
    border-bottom: .25rem solid var(--primaryColor);
}

.contact-section p {
    font-size: 90%;
    color: rgba(0,0,0,.5);
}

@media (max-width: 768px) {
    .contact-container {
        display: block;
        width: 80%;
    }

    .contact-card {
        margin-bottom: 1rem;
    }
}
.footer {
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: var(--darkGrey);
}

.footer > .container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer p {
    color: rgba(255,255,255,.5);
    font-size: 80%;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0.75rem 2rem;
  background: transparent;
  z-index: 1;
}
.nav-header {
  display: flex;
  justify-content: space-between;
}
.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  font-size: 1.5rem;
  color: var(--mainWhite);
}
.nav-links {
  height: 0;
  overflow: hidden;
  -webkit-transition: var(--mainTransition);
  transition: var(--mainTransition);
  list-style-type: none;
  /* background-color: var(--mainWhite); */
} 
.nav-links a {
  font-family: 'Open Sans';
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  color: rgba(255,255,255,.8);
  -webkit-transition: var(--mainTransition);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
  color: 30%;
  color: var(--mainWhite, 30%);
}
.show-nav {
  height: auto;
}
.logo-div {
  /* width: 250px; */
  height: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-text {
  font-family: 'Open Sans';
  color: rgba(255,255,255,.8);
  text-decoration: none;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: var(--mainSpacing);
}


.navbar.nav-scrolled {
  background-color: var(--mainGrey);
}

.logo-text.nav-scrolled {
  color: var(--darkGrey);
}

.nav-icon.nav-scrolled {
  color: var(--darkGrey);
}

.nav-links.nav-scrolled a {
  color: var(--darkGrey);
  background-color: var(--mainGrey);
}

.nav-links.nav-scrolled a:hover {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 4rem;
    background-color: transparent;
  }
  .nav-links a {
    margin: 0 1rem;
    padding: 0.5rem 0;
  }
}
