.about-section {
    padding-top: 8rem;
    padding-bottom: 8rem;
    background: #272727;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #333333, #272727, #161616);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #333333, #272727, #161616); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.about-section h1 {
    color: var(--mainWhite);
    font-size: 1.9rem;
    letter-spacing: var(--mainSpacing);
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.about-section p {
    color: rgba(255,255,255,.5);
    /* width: 50vw; */
    font-size: 1rem;
    letter-spacing: var(--mainSpacing);
    line-height: 1.5;
    font-weight: 500;
}

.about-section p > a {
    color: var(--primaryColor);
    font-size: 1rem;
    letter-spacing: var(--mainSpacing);
    line-height: 1.5;
    font-weight: 500;
    text-decoration: none;

}
.about-section p > a:hover {
    color: var(--primaryColorLight);
    
}

@media (min-width: 992px) {
    .about-section p {
        max-width: 66.666667%;
        margin-left: auto;
        margin-right: auto;
    }
}